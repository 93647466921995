.patient-lifecycle {
  &__dot {
    display: flex;
    position: absolute;
    left: 19px;
    background-color: #fff;
    top: 35px;
    &-date {
      text-align: right;
      line-height: 1.25;
      color: @c-text;
      font-size: 12px;
    }
    &-icon {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #DBF0FF;
      border-radius: 100%;
      margin-left: 16px;
      z-index: 2;
      pointer-events: none;
      background-color: #fff;
    }
  }
  &__content {
    display: block;
    color: @c-text;
    padding: 20px 30px 20px 136px;
    &:hover {
      background-color: @c-primary-15;
      cursor: pointer;
    }
    &-is-today {
      background-color: @c-primary-15;
      &:before {
        content: '';
        border-left: 2px solid @c-primary;
        position: absolute;
        height: ~"calc(100% + 12px)";
        left: 103px;
        top: 0;
      }
    }
  }
  .crm-filters__body {
    padding: 24px;
    border-bottom: 1px solid @c-gray-1;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-timeline {
    padding-top: 18px;
  }
  .ant-timeline-item {
    padding: 0;
  }
  .ant-timeline-item-head-custom {
    transform: none;
    left: 0;
    top: 0;
  }
  .ant-timeline-item-last {
    & > .ant-timeline-item-tail {
      display: block;
      height: 45px;
    }
    .patient-lifecycle__content-is-today:before {
      height: 45px;
    }
  }
  .ant-timeline-item-tail {
    height: ~"calc(100% + 20px)";
    left: 103px;
    top: 0;
  }
  .ant-timeline-item-content {
    margin-left: 0;
    top: 0;
  }
  .patient-lifecycle__divider {
    &-dot {
      position: absolute;
      padding: 4px 15px;
      width: 72px;
      height: 26px;
      left: 68px;
      top: -21px;
      color: @c-text;
      background-color: #fff;
      border: 2px solid @c-gray-5;
      border-radius: 20px;
      z-index: 1;
      &-today {
        background-color: @c-primary;
        border-color: @c-primary;
        color: #fff;
      }
      &-hidden {
        display: none;
      }
    }
    &-content {
      position: relative;
      height: 5px;
      margin-top: 21px;
      .patient-lifecycle__divider-content-bg {
        height: 14px;
        top: -9px;
        position: relative;
        border-top: 2px solid @c-gray-5;
      }
      &-today {
        .patient-lifecycle__divider-content-bg {
          border-color: @c-primary;
          background-color: @c-primary-15;
        }
      }
      &-end {
        margin-top: 0;
        height: 0;
        top: 7px;
        .patient-lifecycle__divider-content-bg {
          height: 0;
        }
      }
    }
  }
}
