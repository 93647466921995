@gap: 8px;

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: @gap;
}

.mt-2 {
  margin-top: @gap * 2;
}

.mt-3 {
  margin-top: @gap * 3;
}

.mt-4 {
  margin-top: @gap * 4;
}

.mt-5 {
  margin-top: @gap * 5;
}

.mt-6 {
  margin-top: @gap * 6;
}

.mt-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: @gap;
}

.mr-2 {
  margin-right: @gap * 2;
}

.mr-3 {
  margin-right: @gap * 3;
}

.mr-4 {
  margin-right: @gap * 4;
}

.mr-5 {
  margin-right: @gap * 5;
}

.mr-6 {
  margin-right: @gap * 6;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: @gap;
}

.mb-2 {
  margin-bottom: @gap * 2;
}

.mb-3 {
  margin-bottom: @gap * 3;
}

.mb-4 {
  margin-bottom: @gap * 4;
}

.mb-5 {
  margin-bottom: @gap * 5;
}

.mb-6 {
  margin-bottom: @gap * 6;
}

.ml-0 {
  margin-left: 0;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: @gap;
}

.ml-2 {
  margin-left: @gap * 2;
}

.ml-3 {
  margin-left: @gap * 3;
}

.ml-4 {
  margin-left: @gap * 4;
}

.ml-5 {
  margin-left: @gap * 5;
}

.ml-6 {
  margin-left: @gap * 6;
}

.m-2 {
  margin: @gap * 2;
}
