.ant-btn-primary {
  &[disabled] {
    background-color: @blue-6;
    color: white;
    opacity: 0.25;
    &:hover {
      background-color: @blue-6;
      color: white;
      opacity: 0.25;
    }
  }
}

.ant-btn-secondary {
  background-color: @c-white;
  color: @c-primary;
  border-color: @c-primary;
  font-weight: bold;
  &:hover {
    background-color: @c-primary-10;
    color: @c-primary;
    border-color: @c-primary;
  }
}

.ant-btn-primary-light {
  background-color: @c-primary-10;
  color: @c-primary;
  border: none;
  font-weight: bold;
  &:hover {
    background-color: @c-primary-10;
    color: @c-primary;
    border: none;
  }
}

.ant-btn-danger-light {
  background-color: @c-red-10;
  color: @c-red-1;
  border: none;
  font-weight: bold;
  &:hover {
    background-color: @c-red-10;
    color: @c-red-1;
    border: none;
  }
}
