.drawer-wrapper {
  .ant-drawer {
    &-header {
      display: none;
    }
    &-body {
      height: 100%;
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 440px;
    }
    &-close {
      display: none;
    }
  }

  .drawer {
    &--header {
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eee;
      padding: 16px 24px;
    }
    &--title {
      font-weight: bold;
      flex: 1;
      font-size: 16px;
    }
    &--body {
      overflow: auto;
      padding: 16px 24px;
      flex: 1;
    }
    &--footer {
      padding: 8px 24px;
      position: sticky;
      z-index: 1;
      bottom: 0;
      background-color: #fff;
    }
  }
}
