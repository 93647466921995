@error-color: #FD6363;
@success-color: @green-7;

.ant-alert {
  border: none;
  .rem(margin-bottom, 18);
  &.ant-alert-error {
    background-color: fadeout(@error-color, 90%);
    .ant-alert-message {
      color: @error-color;
    }
  }

  &.ant-alert-success {
    background-color: fadeout(@success-color, 90%);
    .ant-alert-message {
      color: @success-color;
    }
  }
}
