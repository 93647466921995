.today-incomes {
  &__body {
    display: grid;
    grid-template-columns: 1.5fr 3fr;
  }

  &__total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-right: @margin-2;

    .ant-statistic {
      display: block;
      padding: @padding-3;
    }

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      position: absolute;
      top: 0;
      right: -@margin-1;
      width: 1px;
      background-color: @divider-color
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 0 @margin-3;

    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: @c-headlines;
    }

    &__labels {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      justify-content: space-between;
      padding: @padding-2 0;

      &:not(:last-child) {
        border-bottom: 1px solid @divider-color;
      }

      span:last-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
