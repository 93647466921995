@crm-list-bg-color: @bg-color;
@crm-list-header-bg-color: @c-white;
@crm-list-header-padding: @padding-1 @padding-3 @padding-3;

.crm-list {
  .crm-page-header {
    padding-bottom: 0;
  }

  .crm-table-header {
    .crm-filters {
      padding-bottom: 0;
    }
  }
}
