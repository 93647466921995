h1, h2, h3, h4, h5 {
  color: @c-headlines;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  font-style: normal;
}
