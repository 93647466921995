.ant-input-lg {
  font-size: @font-size-base;
  min-height: @height-lg;
}

.ant-input-affix-wrapper-lg .ant-input-lg {
  min-height: unset;
}

.ant-input-affix-wrapper-lg {
  padding: 8px 11px;
  font-size: @font-size-base;
}

.ant-input-number-lg,
.ant-input-group-lg .ant-input {
  font-size: @font-size-base;
}
