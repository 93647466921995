.inner-layout-content {
  .rem(padding, 40);
}

.h-card {
  background-color: @c-white;
  &--header {
    padding: 17px 24px;
    border-bottom: 1px solid @c-gray-1;
  }
  &--title {
    .rem(font-size, 20);
    line-height: 150%;
    font-weight: 600;
  }
  &--action {
    color: @c-primary;
    font-weight: bold;
    cursor: pointer;
  }
  &--content {
    padding: 17px 24px;
    &.no-padding {
      padding: 0;
    }
  }
  &--row {
    padding: 16px 0;
    min-width: 0;
    border-bottom: 1px solid @c-gray-1;
  }
  &.top-margin {
    .rem(margin-top, 40);
  }
}

app-card-row {
  .h-card--row {
    padding: 16px 0;
    min-width: 0;
    border-bottom: 1px solid @c-gray-1;
  }
  &:last-child {
    .h-card--row {
      border-bottom: 0;
    }
  }
}

.top-divided-list {
  border-top: 1px solid @c-gray-1;
  .top-divided-list--item {
    .rem(padding-top, 16);
    .rem(padding-bottom, 16);
    border-bottom: 1px solid @c-gray-1;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.layout-base {
  height: 100%;
}

.circle-divider {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  position: relative;
  background-color: @c-text;
  top: -3px;
}

.or-divider {
  display: flex;
  align-items: center;
  span {
    color: @c-text;
    &:first-child, &:last-child {
      flex: 1;
      height: 1px;
      background-color: @c-gray-1;
    }
    &:nth-child(2) {
      padding: 0 40px;
    }
  }
}

.line-divider {
  border-bottom: 1px solid @c-gray-1;
}

.non-bordered-input {
  width: 100%;
  border: none;
  outline: none;
}

.bordered-label {
  padding: 8px 12px;
  border: 1px solid @c-gray-1;
  border-radius: 4px;
}
