@modal-header-height: 60px;
@modal-actions-height: 60px;

.ant-modal {
  max-height: calc(100dvh - 200px);

  &.narrow {
    width: 360px !important;
  }

  &.narrow-1 {
    width: 600px !important;
  }

  &.default {
    width: 720px !important;
  }

  &.wide {
    width: 1100px !important;
  }

  &-wrap {
    display: flex;
    justify-content: center;
  }

  &-footer {
    .crm-action-buttons {
      .crm-action-button:last-child {
        margin-left: auto;
      }
    }
  }
}

.ant-modal-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .ant-modal-header {
    padding: 0 @padding-3;
    min-height: @modal-header-height;
    display: flex;
    align-items: center;

    .ant-modal-title {
      .rem(font-size, 20);
      line-height: 150%;
      font-weight: bold;
      color: @c-gray-9;
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    padding: @padding-3;
    overflow: auto;
  }

  .ant-modal-footer {
    padding: @padding-1 @padding-3;
  }

  .ant-modal-confirm-body-wrapper {
    padding: @padding-3 0 0;
  }
}

.ant-modal-confirm-confirm {
  .ant-modal-content {
    height: auto;
  }

  .ant-modal-confirm-body {
    .ant-modal-confirm-content {
      margin: 0 @padding-5;
    }

    & > .anticon {
      color: @c-red-1;
    }
  }
}

.ant-modal-footer {
  .ant-btn {
    text-transform: uppercase;
  }
}


.ant-modal-fs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: auto !important;
  transform-origin: unset !important;
  padding: 0;
  max-width: none;
  max-height: 100dvh;
}

.ant-modal.edit-draft-modal {
  .ant-modal-fs;
  left: unset;
  right: unset;
  top: 10px;
  bottom: 10px;

  .ant-modal-content {
    max-height: 100%;
    border-radius: 0;
  }

  .ant-modal-body {
    height: ~"calc(100% - @{modal-header-height})";
  }

  .modal__form-component {
    padding: 0;
  }
}

@media screen and (max-width: 1100px) {
  .ant-modal {
    &.wide {
      .ant-modal-fs;
    }

    &-content {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }

    &-confirm {
      .ant-modal-content {
        height: auto;
      }
    }

    &-body {
      height: ~"calc(100% - @{modal-header-height})";
    }
  }
}

@media screen and (max-width: 720px) {
  .ant-modal {
    &.default {
      .ant-modal-fs;
    }
  }
}

@media screen and (max-width: 600px) {
  .ant-modal {
    &.narrow-1 {
      .ant-modal-fs;
    }
  }
}

@media screen and (max-width: 360px) {
  .ant-modal {
    &.narrow {
      .ant-modal-fs;
    }
  }
}
