.layout-version {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: @padding-2;
  text-align: center;

  &__name {
    font-weight: 700;
  }

  &__image {
    display: block;
    margin-top: @margin-1;
    width: 34px;
  }

  &__version__env {
    text-transform: uppercase;
  }

  &__module-tooltip {
    width: 120px;
  }

  &__module-version {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
  }
}
