@left: 120px;

.next-appointments {
  .ant-timeline.ant-timeline-label {
    margin: -12px 0;

    .ant-timeline-item {
      cursor: pointer;
      padding: 12px 0;
      border-radius: @border-radius-base;

      &:hover {
        background-color: @c-primary-10;
      }

      &-label {
        top: calc(50% - 22px);
        width: calc(@left - 20px);
      }

      &-tail {
        top: calc(50% - 5px);
        height: 100%;
        left: @left;
      }

      &-head {
        top: calc(50% - 5px);
        left: @left;
      }

      &-content {
        top: 0;
        left: calc(@left - 4px);
        width: calc(100% - 144px);
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    margin: -12px 0 -12px -144px;
    padding: 12px 8px 12px 144px;
  }

  &__content {
    flex: 1;
  }

  &__name {
    font-size: 18px;
    color: @c-headlines;
    font-weight: bold;
  }
}
