@import (reference) '../../global/variables';

.card-summary {
  background-color: @c-gray-4;
  border: 1px solid @c-gray-5;

  &__row {
    display: flex;
    justify-content: space-between;
    padding: @padding-2 0;

    &:not(:last-child) {
      border-bottom: 1px solid @c-gray-5;
    }

    &--total {
      font-size: 20px;
    }
  }

  .ant-card-body {
    padding: @padding-2 @padding-3;
  }
}
