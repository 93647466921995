.crm-card {
  &-row {
    &--value {
      a {
        color: @text-color;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
