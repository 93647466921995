.ant-drawer {
  &-title {
    font-weight: bold;
  }
  
  &-close {
    order: 1;
    margin: 0;
    padding: 0;
  }
}
