.email-template {
  &__body {
    display: grid;
    grid-template-columns: 210mm 1fr;
    gap: @padding-3;
    height: 100%;
    padding: @padding-3;
  }

  &__editor {
    height: 100%;
    overflow: hidden;

    editor {
      height: 100%;
      overflow: hidden;

      .tox-tinymce {
        height: 100% !important;
      }
    }
  }
}
