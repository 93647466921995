.drawer-detail-wrapper {
  .ant-drawer-body {
    padding-bottom: 0;
  }
}

.drawer-detail {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__actions {
    position: sticky;
    bottom: 0;
    padding: @padding-3 0;
    margin-top: auto;
    background: #fff;

    .ant-space {
      width: 100%;
    }

    &__popover {
      width: 440px;

      .ant-space {
        width: 100%;
      }
    }
  }

  .crm-card {
    border: 0;

    .ant-card-body {
      padding: 0;
    }

    &-row {
      border-bottom: 1px solid @border-color-base;
    }
  }
}
