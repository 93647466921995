.flex {
  display: flex;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.full-flex-grow {
  flex-grow: 1;
}

.no-wrap {
  flex-wrap: nowrap;
}

.direction-column {
  flex-direction: column;
}
