@auth-wrapper-content-width: 480px;
@auth-success-logo-width: 128px;

.crm-login {
  .crm-auth-wrapper {
    &--content {
      display: flex;
      flex-direction: column;

      &-body {
        order: 1;
      }

      &-header {
        order: 2;
      }
    }

    &--logo {
      margin-top: 64px;
      margin-bottom: 0;

      img {
        width: 170px;
      }
    }

    &--image {
      padding: 40px;

      .login-image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 100%;
        background-color: #ffffff;

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }
    }
  }

  .login-alert {
    margin: 24px 0;
  }

  .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .login-text {
    font-size: 16px;
  }

  .login-forgotten-password {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-right: 8px;
    }
  }

  .login-no-account {
    margin: 24px 0;
    text-align: center;
  }

  @media (max-width: 767px) {
    .crm-auth-wrapper {
      flex-direction: column;
      justify-content: flex-start;

      &--content-wrapper {
        order: 2;
      }

      &--image {
        order: 1;
        min-height: 260px;
        width: 100%;
        padding: 0;
      }
    }

    .login-title {
      font-size: 20px;
    }

    .login-text {
      font-size: 14px;
    }
  }
}

.crm-forgotten-password,
.crm-reset-password {
  .crm-auth-wrapper--image {
    display: none;
  }

  .crm-auth-wrapper--logo {
    margin-bottom: 32px;
    background-image: url(/assets/icons/lock.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 128px;

    img {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .crm-auth-wrapper--logo {
      height: 72px;
      margin-bottom: 24px;
    }
  }
}

.crm-forgotten-password {
  .crm-forgotten-password--info {
    margin-bottom: 32px;
  }

  @media (max-width: 767px) {
    .crm-forgotten-password--title {
      font-size: 20px;
    }

    .crm-forgotten-password--text {
      font-size: 14px;
    }

    .crm-auth-wrapper--logo {
      height: 72px;
      margin-bottom: 24px;
    }
  }
}

.crm-reset-password {
  .crm-reset-password--info {
    margin-bottom: 32px;
  }

  .crm-reset-password--title {
    margin-bottom: 16px;
  }

  .crm-reset-password--text {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    .crm-reset-password--title {
      font-size: 20px;
    }

    .crm-reset-password--text {
      font-size: 14px;
    }
  }
}
