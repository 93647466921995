.checkbox-item {
  display: flex;
  align-items: center;
  padding: @padding-1 @padding-2;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  cursor: pointer;

  &__message {
    flex: 1;
  }
}
