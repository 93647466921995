// BREAKPOINTS CORRESPONDING WITH ANT

@media (max-width:575px)  { // XS
}

@media (min-width:576px)  { // SM
}

@media (min-width:768px)  { // MD
  .auth-card {
    min-width: 570px;
  }
}

@media (min-width:992px)  { // LG
  .herriot-login__image {
    flex: 1;
  }
  crm-layout {
    & > .layout-base {
      & > nz-layout > nz-sider.ant-layout-sider {
        position: relative;
        top: unset;
        bottom: unset;
        z-index: unset;
        &.ant-layout-sider-collapsed {
          width: @menu-collapsed-width !important;
          flex: 0 0 @menu-collapsed-width !important;
          min-width: @menu-collapsed-width !important;
          max-width: @menu-collapsed-width !important;
        }
      }
    }
  }
  .calendar-layout {
    .calendar-layout__sider {
      position: relative;
      top: unset;
      bottom: unset;
      z-index: unset;
      &__header {
        display: none;
      }
    }
  }
  .detail-appointment {
    display: flex;
    .detail-appointment--info {
      padding-right: 24px;
      border-bottom: none;
      border-right: 1px solid @c-gray-1;
      min-width: 0;
      flex: 1;
    }
    .detail-appointment--staff-service {
      padding-left: 24px;
      max-width: 350px;
      flex: 1;
      display: block;
    }
  }
  .edit-draft {
    .edit-draft--summary {
      width: 50%;
      margin-left: auto;
    }
  }
  .new-invoice {
    .new-invoice--summary {
      width: 50%;
      margin-left: auto;
    }
  }
  .draft-detail {
    .draft-detail--summary {
      max-width: 600px;
      margin-left: auto;
    }
  }
  .invoice {
    .invoice-body {
      .invoice-body--summary {
        min-width: 500px;
      }
    }
  }
}

@media (min-width:1200px) { // XL
  .record-test-results {
    .record-test-results__content {
      justify-content: space-between;
      table {
        width: auto!important;
        flex: 1!important;
      }
      .histograms {
        margin-left: 40px;
        display: block!important;
        width: auto!important;
      }
    }
  }
}

@media (min-width:1600px) { // XXL
}
