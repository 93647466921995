@gap: 8px;

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: @gap;
}

.pt-2 {
  padding-top: @gap * 2;
}

.pt-3 {
  padding-top: @gap * 3;
}

.pt-4 {
  padding-top: @gap * 4;
}

.pt-5 {
  padding-top: @gap * 5;
}

.pt-6 {
  padding-top: @gap * 6;
}

.pt-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: @gap;
}

.pr-2 {
  padding-right: @gap * 2;
}

.pr-3 {
  padding-right: @gap * 3;
}

.pr-4 {
  padding-right: @gap * 4;
}

.pr-5 {
  padding-right: @gap * 5;
}

.pr-6 {
  padding-right: @gap * 6;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: @gap;
}

.pb-2 {
  padding-bottom: @gap * 2;
}

.pb-3 {
  padding-bottom: @gap * 3;
}

.pb-4 {
  padding-bottom: @gap * 4;
}

.pb-5 {
  padding-bottom: @gap * 5;
}

.pb-6 {
  padding-bottom: @gap * 6;
}

.pl-0 {
  padding-left: 0;
}

.pl-auto {
  padding-left: auto;
}

.pl-1 {
  padding-left: @gap;
}

.pl-2 {
  padding-left: @gap * 2;
}

.pl-3 {
  padding-left: @gap * 3;
}

.pl-4 {
  padding-left: @gap * 4;
}

.pl-5 {
  padding-left: @gap * 5;
}

.pl-6 {
  padding-left: @gap * 6;
}

.p-2 {
  padding: @gap * 2;
}
