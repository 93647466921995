.appointment-status {
  &.planned {
    color: @c-primary;
  }

  &.started, &.completed {
    color: @c-green-2;
  }

  &.cancelled {
    color: @c-red-1;
  }
}
