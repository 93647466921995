.crm-table {
  &-header {
    .crm-filters {
      padding-bottom: @padding-3;
    }
  }
}

.table-selection-modal {
  display: flex;
  max-height: 100%;
  flex: 1;
  min-height: 0;
  overflow: hidden;
  padding-bottom: 24px;

  crm-table {
    width: 100%;
  }

  .crm-table {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    &-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;

      .ant-table-wrapper {
        flex: 1;
        min-height: 0;
        overflow: auto;
      }
    }
  }
}
