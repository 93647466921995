.record-layout {
  height: 100%;
  overflow-x: hidden;
}

.record-header--wrapper {
  padding: 0;
}

.record-content {
  height: 100%;
}

.record-content-layout {
  height: 100%;
}

.record-sider {
  height: 100%;

  &__collapse {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 12px;
    right: -39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #ecebeb;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
  }

  &__title {
    display: block;
    padding: 16px;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    border-bottom: 1px solid @c-gray-1;

    &:hover {
      color: #000000;
      text-decoration: underline;
    }
  }

  &__body {
    padding: 16px;
  }

  &__row {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid @c-gray-1;
    }
  }

  .ant-layout-sider-children {
    height: 100%;
    overflow: auto;
  }

  .collapsed-icon {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.record-header {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1.5;

  .back {
    height: 100%;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid @c-gray-1;
  }

  .main-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-left: 24px;
  }

  .actions {
    padding-right: 24px;
  }
}

.record-form--container {
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.record-form--wrapper {
  max-width: 1080px;
  width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.record-form {
  padding: 24px;
  background-color: #ffffff;
}

.record-history {
  &__header {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    padding: 12px 24px;
    font-size: 20px;
    border-bottom: 1px solid @c-gray-1;

    .collapsed-btn {
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  &__title {
    min-width: 0;
    white-space: normal;
  }

  &__timeline {
    padding: 24px 0 24px 24px;
    margin-top: 12px;

    .ant-timeline-item {
      padding-bottom: 24px;

      &-tail {
        top: 25px;
        height: ~"calc(100% - 20px)";
      }
      &-head {
        top: 18px;
      }
      &-content {
        margin: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__item {
    margin: -24px 0 0 -24px;
    padding: 16px 24px 16px 44px;

    &--selected, &:hover {
      background-color: @c-primary-15;
    }
  }

  &__save-mode {
    font-size: 12px;
    font-weight: bold;
  }
}
