.signature-layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-layout-header {
    display: flex;
    justify-content: flex-end;
    padding: 0 @padding-3;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: @padding-3;
    min-height: 0;
  }


  &__logo {
    margin-top: 16vh;
    text-align: center;

    img {
      max-height: 200px;
      max-width: 90%;
    }
  }

  &__waiting {
    font-size: min(10vh, 10vw);
    text-align: center;
    margin-top: 1vh;
  }

  &__self-service {
    display: flex;
    flex: 1;

    a {
      margin-top: auto;
    }
  }
}

::ng-deep .signer-pad {
  padding: 0 30px;
}
