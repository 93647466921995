.ant-checkbox-checked {
  .ant-checkbox-inner {
    &::after {
      top: 6px;
      left: 3px;
    }
  }
}

.ant-checkbox-wrapper {
  color: @c-text;
}
