.appointment-history {
  &__header {
    height: 80px;
    display: flex;
    border-top: 1px solid @border-color-base;
    border-bottom: 1px solid @border-color-base;
    margin: -24px -24px 24px;
  }

  &__back {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid @border-color-base;
  }

  &__title {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}

.appointment-detail {
  &__entities {
    flex: 1;
  }
}
