.test-request {
  &__waiting-state {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      width: 72px;
      height: 72px;
      background-color: rgba(#1890ff, 0.2);
      background-color: aliceblue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4fadff;
      font-size: 20px;
      margin-bottom: @margin-lg;
    }

    &__content {
      width: 50%;
      text-align: center;
    }
  }

  &__header {
    margin-bottom: @margin-lg;

    h1 { margin-bottom: 0; }

    h5 { margin-bottom: 0; color: #4f4f4f; }
  }

  &__items {
    margin-bottom: @margin-lg;

    span {
      margin-left: 8px;
    }
  }
}
