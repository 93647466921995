.crm-page-header {
  &__body-content-image {
    max-height: 30px;
    width: auto;
    margin-right: 16px;
  }

  &__body-header {
    margin-bottom: 8px;
  }

  .crm-action-button {
    .ant-btn {
      text-transform: uppercase;
    }
  }
}
