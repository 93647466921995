.calendar-mover {
  display: flex;
  justify-content: center;
  flex: 1;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 @padding-2;
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
  }

  &__message {
    font-size: 12px;
  }
}
