.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.break-word {
  word-break: break-word;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
