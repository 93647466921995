.resource-selector {
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
  }

  &__select {
    padding: @padding-2 @padding-2 0;
  }

  &__search {
    padding: @padding-2;
    border-bottom: 1px solid @border-color-base;
  }

  &__types {
    &__all {
      padding: 0 @padding-2 @padding-2;
    }

    &__options {
      padding: @padding-2;
      border-top: 1px solid @border-color-base;

      .checkbox-item + .checkbox-item {
        margin-top: @margin-1;
      }
    }
  }
}
