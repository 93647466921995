.self-service-layout {
  .ant-layout-header {
    display: flex;
    justify-content: flex-end;
    padding-right: @padding-3;
  }

  .ant-layout-content {
    padding: @padding-3;
  }

  &__content {
    background-color: #fff;
    padding: @padding-3;
    max-width: 800px;
    margin: auto;
  }
}
