.event-lifecycle-item {
  display: flex;
  align-items: center;
  &__actions {

  }
  &__content {
    flex: 1;
    margin-right: 16px;
  }
  &__type {
    font-size: 12px;
    font-weight: bold;
    color: @c-text;
  }
  &__info {
    font-size: 12px;
    color: #BFBFBF
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    color: @c-headlines;
  }
  &__status {
    font-size: 12px;
    font-weight: bold;
  }
}
