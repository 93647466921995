.record-related {
  margin-top: 24px;

  &__list-item {
    background-color: @c-gray-2;
    border: 1px solid @c-gray-5;

    .content {
      padding: 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &__icons {
      app-tooltip-icon:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }

  &__item {
    flex: 1;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &__title {
      color: @c-gray-8;
      font-size: 16px;
      line-height: 110%;

    }

    &__content {
      display: flex;

      & > *:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
