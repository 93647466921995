.test-result {
  min-height: 100%;

  &__title {
    font-weight: bold;
    font-size: 20px;
    color: @c-text;
    margin-bottom: 10px;
  }

  &--date {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 16px;
  }

  &__table {
    table {
      width: 100%;

      th {
        white-space: nowrap;
        text-align: left;
      }

      td, th {
        font-size: 16px;
        padding: 4px 0;
      }
    }
  }

  &__histograms {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .histogram {
      margin-bottom: 16px;
    }
  }

  &__images {
    margin-top: 16px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &__image {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__info label {
    font-weight: bold;
  }

  &__customer {
    margin-bottom: 8px;
  }

  &__invalid {
    color: @c-red-1;
  }
}

