.cases {
  .ant-card-body {
    padding: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: @padding-3;

    .ant-divider {
      height: 32px;
      margin: 0 @margin-3;
    }
  }

  .crm-table {
    padding: @padding-2 @padding-3 @padding-3;
  }
}
