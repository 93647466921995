.record-lifecycle-item {
  display: flex;
  align-items: center;
  &__actions {

  }
  &__content {
    flex: 1;
    margin-right: 16px;
  }
  &__type {
    font-size: 12px;
    font-weight: bold;
    color: @c-text;
  }
  &__info {
    font-size: 12px;
    color: @c-text;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    color: @c-headlines;
  }
  &__status {
    font-size: 12px;
    font-weight: bold;
  }
}

.lifecycle-related-records {
  margin-top: 20px;
  &__row {
    padding: 3px 30px;
    background-color: #F5F5F5;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    color: @c-text;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: @c-gray-5;
    }
    &-info {
      font-size: 12px;
      color: #BFBFBF
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-status {
      font-weight: bold;
      width: 120px;
      text-align: right;
    }
  }
  .ant-collapse {
    border-radius: 6px;
    background-color: transparent;
    .ant-collapse-header {
      font-weight: bold;
      background-color: #F5F5F5;
      border-radius: 6px;
      color: @c-text;
      padding: 12px 30px;
    }
    .ant-collapse-content-box {
      padding-top: 4px;
      padding-bottom: 0;
    }
  }
}
