.ant-time-picker-input {
  border-color: @c-gray-1;
}

.ant-time-picker-large {
  .ant-time-picker-input {
    font-size: 14px;
  }
}

.ant-time-picker-panel-select {
  min-height: 192px;
  ul {
    padding-bottom: 0;
  }
}

.ant-time-picker-icon {
  pointer-events: none;
}

.ant-picker-time-panel {
  .ant-picker-footer {
    .ant-picker-ranges {
      padding: 10px 12px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-picker-ok {
        button {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
