// Convert px to rem
.rem(@selector, @pixels) {
  @base-font-size: 16;
  @remValue: round((@pixels / @base-font-size), 3);
  @{selector}: ~'@{remValue}rem';
}

.rem-padding(@top, @right, @bottom, @left) {
  .rem(padding-top, @top);
  .rem(padding-right, @right);
  .rem(padding-bottom, @bottom);
  .rem(padding-left, @left);
}

.rem-padding(@top, @right) {
  .rem-padding(@top, @right, @top, @right);
}
