.centered-container {
  min-height: 100%;
  display: flex;
  align-items: stretch;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
