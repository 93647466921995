.clickable {
  outline: none;
  cursor: pointer;

  &.underline {
    &:hover {
      text-decoration: underline;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.headline-text {
  color: @c-headlines;
}

.gray-text {
  color: @c-text;
}

.gray-text-3 {
  color: @c-gray-3;
}

.green-text {
  color: @c-green-1;
}

.red-text {
  color: @c-red-1;
}

.blue-text {
  color: @c-primary;
}

.medical-record-status {
  &.closed_correctly, &.closed_incorrectly {
    color: @c-green-2;
  }
  &.opened {
    color: @c-red-1;
  }
}

.appointment-status {
  &.planned {
    color: @c-primary;
  }
  &.started, &.completed {
    color: @c-green-2;
  }
  &.cancelled {
    color: @c-red-1;
  }
}

.loading__overlay {
  &.frosted {
    background-color: transparent;
    z-index: 1001!important;
  }
}

.circle-icon {
  border-radius: 100%;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.red {
    background-color: @c-red-3;
  }
  &.blue {
    background-color: @c-primary-20;
  }
  &.gray {
    background-color: @c-gray-2;
  }
  &.green {
    background-color: @c-green-3;
  }
}
