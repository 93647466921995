.cash-flow {
  .ant-divider {
    margin: @margin-3 0;
  }
}

.cash-flow-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: @margin-3;

  &__title {
    h4 {
      margin-bottom: 0;
    }
  }

  &__caption {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: @c-gray-7;
  }

  &__legend {
    display: flex;
    align-items: center;

    &__indicator {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: @margin-1;
    }

    &__item:not(:last-child) {
      margin-right: @margin-3;
    }
  }
}
