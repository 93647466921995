.ant-dropdown-dark {
  .ant-dropdown-menu {
    position: relative;
    top: 15px;
    &:before {
      content: ' ';
      border-bottom: 10px solid @c-dark;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      right: 15px;
      top: -10px;
    }
    background-color: @c-dark;
    .ant-dropdown-menu-item {
      color: @c-gray-3;
      &:hover {
        background-color: @c-dark-1;
      }
    }
  }
}
