@main-border-radius: 16px;
@main-margin-horizontal: 50px;

@main-info-primary-message-fs: 36px;
@main-info-secondary-message-fs: 24px;
@main-info-note-fs: 16px;

@main-icon-dimension: 64px;
@main-icon-border-radius: 12px;
@main-icon-margin-main: 60px;
@main-icon-margin-right: 32px;
@main-icon-size: 32px;

@info-margin-horizontal: 74px;
@info-min-height: 24px;
@info-border-radius: 12px;

@info-icon-margin-main: 36px;
@info-icon-margin-right: 32px;
@info-icon-font-size: 24px;

@info-info-left-max-width: 265px;
@info-info-right-margin-right: 36px;
@info-info-primary-fs: 24px;
@info-info-secondary-fs: 16px;

@info-divider-height: 72px;
@info-divider-margin-horizontal: 32px;

@md-scale: 1.5;
@lg-scale: 2;

.attendance-event {
  width: 100%;

  &__main {
    background-color: @c-green-2;
    border-radius: @main-border-radius;
    margin: 0 @main-margin-horizontal;
    display: flex;

    &.error {
      background-color: @c-red-1;

      .attendance-event__main__icon {
        background-color: #FD8282;
      }
    }

    &__icon {
      color: #fff;
      min-width: @main-icon-dimension;
      min-height: @main-icon-dimension;
      width: @main-icon-dimension;
      height: @main-icon-dimension;
      border-radius: @main-icon-border-radius;
      background-color: #6CD489;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: @main-icon-margin-main @main-icon-margin-right @main-icon-margin-main @main-icon-margin-main;

      .anticon {
        font-size: @main-icon-size;
      }
    }

    &__info {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .primary-message {
        font-size: @main-info-primary-message-fs;
        line-height: 1.25;
        font-weight: bold;
      }

      .secondary-message {
        font-size: @main-info-secondary-message-fs;
        line-height: 1.5;
        font-weight: bold;
      }

      .note {
        font-size: @main-info-note-fs;
        line-height: 1.5;
      }
    }
  }

  &__info {
    background-color: #fff;
    margin: 0 @info-margin-horizontal;
    border-bottom-left-radius: @info-border-radius;
    border-bottom-right-radius: @info-border-radius;
    display: flex;
    align-items: center;
    min-height: @info-min-height;

    &__icon {
      border-radius: 100%;
      width: @main-icon-dimension;
      height: @main-icon-dimension;
      background-color: @c-gray-2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: @info-icon-margin-main @info-icon-margin-right @info-icon-margin-main @info-icon-margin-main;

      &.alt {
        background-color: #FFEFEF;

        .anticon {
          color: @c-red-1;
        }
      }

      .anticon {
        font-size: @info-icon-font-size;
        color: @c-text-2;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.alt {
        color: @c-red-1;
      }

      &.full {
        flex: 1;
      }

      &.left {
        max-width: @info-info-left-max-width;
      }

      &.right {
        margin-right: @info-info-right-margin-right;
      }

      .primary {
        font-size: @info-info-primary-fs;
        line-height: 1.5;
      }

      .secondary {
        font-size: @info-info-secondary-fs;
        line-height: 1.5;
      }
    }

    &__divider {
      width: 1px;
      height: @info-divider-height;
      margin: 0 @info-divider-margin-horizontal;
      background-color: #C4C4C4;
    }
  }
}

@media screen and (min-width: 1200px) {
  .attendance-event {
    &__main {
      margin: 0 @main-margin-horizontal * @md-scale;
      border-radius: @main-border-radius * @md-scale;

      &__icon {
        min-width: @main-icon-dimension * @md-scale;
        min-height: @main-icon-dimension * @md-scale;
        width: @main-icon-dimension * @md-scale;
        height: @main-icon-dimension * @md-scale;
        margin: @main-icon-margin-main * @md-scale @main-icon-margin-right * @md-scale @main-icon-margin-main* @md-scale @main-icon-margin-main* @md-scale;
        border-radius: @main-icon-border-radius * @md-scale;

        .anticon {
          font-size: @main-icon-size * @md-scale;
        }
      }

      &__info {
        .primary-message {
          font-size: @main-info-primary-message-fs * @md-scale;
        }

        .secondary-message {
          font-size: @main-info-secondary-message-fs * @md-scale;
        }

        .note {
          font-size: @main-info-note-fs * @md-scale;
        }
      }
    }

    &__info {
      margin: 0 @info-margin-horizontal * @md-scale;
      border-bottom-left-radius: @info-border-radius * @md-scale;
      border-bottom-right-radius: @info-border-radius * @md-scale;
      min-height: @info-min-height * @md-scale;

      &__icon {
        width: @main-icon-dimension * @md-scale;
        height: @main-icon-dimension * @md-scale;
        margin: @info-icon-margin-main * @md-scale @info-icon-margin-right * @md-scale @info-icon-margin-main * @md-scale @info-icon-margin-main * @md-scale;

        .anticon {
          font-size: @info-icon-font-size * @md-scale;
        }
      }

      &__info {
        &.left {
          max-width: @info-info-left-max-width * @md-scale;
        }

        &.right {
          margin-right: @info-info-right-margin-right * @md-scale;
        }

        .primary {
          font-size: @info-info-primary-fs * @md-scale;
        }

        .secondary {
          font-size: @info-info-secondary-fs * @md-scale;
        }
      }

      &__divider {
        height: @info-divider-height * @md-scale;
        margin: 0 @info-divider-margin-horizontal * @md-scale;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .attendance-event {
    &__main {
      margin: 0 @main-margin-horizontal * @lg-scale;
      border-radius: @main-border-radius * @lg-scale;

      &__icon {
        min-width: @main-icon-dimension * @lg-scale;
        min-height: @main-icon-dimension * @lg-scale;
        width: @main-icon-dimension * @lg-scale;
        height: @main-icon-dimension * @lg-scale;
        margin: @main-icon-margin-main * @lg-scale @main-icon-margin-right * @lg-scale @main-icon-margin-main* @lg-scale @main-icon-margin-main* @lg-scale;
        border-radius: @main-icon-border-radius * @lg-scale;

        .anticon {
          font-size: @main-icon-size * @lg-scale;
        }
      }

      &__info {
        .primary-message {
          font-size: @main-info-primary-message-fs * @lg-scale;
        }

        .secondary-message {
          font-size: @main-info-secondary-message-fs * @lg-scale;
        }

        .note {
          font-size: @main-info-note-fs * @lg-scale;
        }
      }
    }

    &__info {
      margin: 0 @info-margin-horizontal * @lg-scale;
      border-bottom-left-radius: @info-border-radius * @lg-scale;
      border-bottom-right-radius: @info-border-radius * @lg-scale;
      min-height: @info-min-height * @lg-scale;

      &__icon {
        width: @main-icon-dimension * @lg-scale;
        height: @main-icon-dimension * @lg-scale;
        margin: @info-icon-margin-main * @lg-scale @info-icon-margin-right * @lg-scale @info-icon-margin-main * @lg-scale @info-icon-margin-main * @lg-scale;

        .anticon {
          font-size: @info-icon-font-size * @lg-scale;
        }
      }

      &__info {
        &.left {
          max-width: @info-info-left-max-width * @lg-scale;
        }

        &.right {
          margin-right: @info-info-right-margin-right * @lg-scale;
        }

        .primary {
          font-size: @info-info-primary-fs * @lg-scale;
        }

        .secondary {
          font-size: @info-info-secondary-fs * @lg-scale;
        }
      }

      &__divider {
        height: @info-divider-height * @lg-scale;
        margin: 0 @info-divider-margin-horizontal * @lg-scale;
      }
    }
  }
}
