.appointment-entity {
  &__title {
    font-size: @font-size-lg;
    font-weight: bold;
    margin-bottom: @margin-1;
  }

  &__value {
    display: flex;
    align-items: center;
    border: 1px solid @border-color-base;
    border-radius: @border-radius-base;
    padding: @padding-1 @padding-2;

     + .appointment-entity__value {
       margin-top: @margin-1;
     }
  }

  &__label {
    flex: 1;
    min-width: 0;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__available {
    .anticon {
      margin-left: @margin-1;
    }

    &--success {
      span {
        color: @green-base;
      }
    }

    &--error {
      span {
        color: @red-base;
      }
    }
  }

  &__delete {
    margin-left: @margin-1;

    span {
      color: @red-base;
    }
  }

  &-icon {
    &--success {
      span {
        color: @green-base;
      }
    }

    &--error {
      span {
        color: @red-base;
      }
    }
  }
}
