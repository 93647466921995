.record-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px @padding-1;
  color: @text-color;

  &:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }

  &__content {
    flex: 1;
    margin-left: @margin-8;
  }

  + .record-item:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    border-top: 1px solid @border-color-base;
  }
}
