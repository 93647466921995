.ant-collapse {
  background-color: #fff;

  .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
}
