.appointment-records {
  display: block;

  .ant-collapse {
    .ant-collapse-header {
      padding: @padding-1 0;
      font-size: @font-size-lg;
      font-weight: bold;
    }

    &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: @padding-1 0;
    }

    &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 0;
    }
  }
}
