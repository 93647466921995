.ant-table {
  color: @c-text;

  table {
    overflow-y: scroll;
  }

  .ant-table-thead {
    & > tr {
      & > th {
        .rem(font-size, 12);
        color: @c-text;
        background-color: @c-white;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-row:nth-child(odd) {
      background-color: @c-white;
    }
    .ant-table-row:nth-child(even) {
      background-color: @c-gray-2;
    }
  }
}

