.ant-layout-sider-children {
  border-right: 1px solid @c-gray-1;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-layout-sider-zero-width {
  .ant-layout-sider-children {
    border: none;
  }
}

.ant-menu {
  .ant-menu-item {
    outline: none;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 3px solid @c-primary;
      transform: scaleY(0.0001);
      opacity: 0;
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      content: '';
    }
    &:after {
      content: none;
    }
  }
  .ant-menu-item-selected {
    color: @c-blue-1;
    font-weight: bold;
    &:before {
      transform: scaleY(1);
      opacity: 1;
      transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

.layout-sider {
  .ant-menu {
    border-right: 0;
    &.ant-menu-inline {
      margin: 30px 0 0;
    }
    &.ant-menu-sub {
      margin: 0;
    }
  }
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title {
  height: 34px;
  line-height: 34px;
}
