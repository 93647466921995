.patient-row {
  .rem(margin-left, -16);
  .rem(margin-right, -16);
  padding: 16px;
  color: @c-text;
  &--dead {
    opacity: 0.5;
  }
  &:hover {
    cursor: pointer;
    background-color: @c-primary-10;
  }
}
