.weekday-selector {
  display: flex;

  &__wd {
    background-color: #F6F6F6;
    border: 1px solid #E7E7E7;
    border-radius: 100%;
    color: #000;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      border-color: #1890FF;
      color: #1890FF;
      background-color: rgba(24, 144, 255, 0.06);
    }

    &--locked {
      cursor: not-allowed;
      color: #d66a46;
      border-color: #d66a46;
    }
  }
}
