.pets-count {
  display: flex;
  .pet {
    margin-right: 8px;
    position: relative;
    img {
      height: 20px;
    }
    .pet-count {
      width: 12px;
      height: 12px;
      color: @c-gray-7;
      background-color: @c-white-1;
      font-size: 10px;
      line-height: 1;
      top: 0;
      right: -4px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
