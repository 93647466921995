.calendar-time-event {
  &--started {
    border-style: dashed;
    position: relative;
    border-bottom: 0;
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
      width: 100%;
      height: 3em;
      max-height: 100%;
    }
  }
}
