.doughnut {
  display: flex;

  &__chart {
    flex: 1;
    min-width: 0;
    max-height: 200px;
    display: flex;
    justify-content: center;

    crm-doughnut-chart {
      display: block;
      height: 100%;
      max-width: 100%;
    }
  }

  &__labels {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__label {
    display: flex;
    align-items: center;
    font-size: @font-size-lg;
    font-weight: 700;

    &__dot {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      margin-right: @margin-1;
    }

    &__name {
      margin-right: @margin-1;
    }

    &:not(:last-child) {
      margin-bottom: @margin-2;
    }
  }
}
