.calendar-override--appointments {
  .crm-calendar-resource {
    &-event {
      &-container {
        &-shift {
          pointer-events: none;
        }
      }
      &-shift {
        background-color: @c-primary-10;
        border: none;
        border-radius: 0;
        color: transparent;
        user-select: none;
      }
    }
  }
}
