@import (reference) '../global/variables';

.ant-card-head-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: @c-headlines;
}

.ant-card-body {
  .crm-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
