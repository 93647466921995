h1,
.h1 {
  .rem(font-size, 30);
  font-weight: 700;
  line-height: 120%;
}

h2,
.h2 {
  .rem(font-size, 20);
  font-weight: 700;
  line-height: 120%;
}

h4, .h4 {
  font-style: normal;
  font-weight: 600;
  .rem(font-size, 18);
  line-height: 150%;
  color: #1A1C1A;
}

p {
  .rem(font-size, 16);
  font-weight: 400;
  line-height: 180%;
}

.label {
  .rem(font-size, 16);
  font-weight: 400;
  line-height: 140%;
}

.label--small {
  .rem(font-size, 14);
  font-weight: 700;
  line-height: 140%;
}

.label--extra-small {
  .rem(font-size, 12);
  font-weight: 700;
  line-height: 140%;
}

.caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #656565;
}
