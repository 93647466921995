@crm-layout-header-profile-margin: 0 0 0 @margin-2;

.crm-layout-header {
  .crm-layout-fragment-logo {
    margin-right: auto;

    .crm-logo img {
      height: 48px;
    }
  }

  .crm-layout-fragment-profile {
    margin: 0 @margin-2;
  }
}

.crm-layout-sider {
  .crm-layout-fragment-menu {
    margin-top: @margin-3;
  }
}
