@import (css) url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.fs-12 {
  .rem(font-size, 12);
  .rem(line-height, 18);
}

.fs-16 {
  .rem(font-size, 16);
  .rem(line-height, 24);
}

.fs-20 {
  .rem(font-size, 20);
  .rem(line-height, 150%);
}

.fs-24 {
  .rem(font-size, 24);
  .rem(line-height, 150%);
}

.fs-18 {
  .rem(font-size, 18);
  .rem(line-height, 150%);
}

.fs-30 {
  .rem(font-size, 30);
  .rem(line-height, 38);
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}
