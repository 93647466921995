.crm-dashboard {
  &__header {
    padding: 0;
  }

  &__body {
    padding-top: 0;
    height: auto;
  }

  .crm-page-header {
    background-color: transparent;
  }
}
