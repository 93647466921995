.calendar-layout {
  display: flex;
  height: 100%;

  &__sider {
    background-color: @c-white;
    overflow-y: hidden;
    position: absolute;
    top: @nav-height;
    bottom: 0;
    z-index: 20;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: @padding-2;
      border-bottom: @border-width-base @border-style-base @border-color-base;

      h2 {
        margin-bottom: 0;
      }

      .anticon {
        cursor: pointer;
      }
    }
  }

  &__picker {
    .ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner.calendar-layout__picker__cell--holiday,
    &__cell--holiday {
      background-color: #e8e8e8 !important;
    }

    .ant-picker {
      height: auto;

      &-panel-container {
        box-shadow: none;
      }

      &-wrapper {
        width: 100%;
      }

      &-date-panel {
        width: auto;

        .ant-picker-content {
          width: auto;
        }
      }

      &-week-panel {
        width: auto;

        .ant-picker-content {
          width: 100%;
        }
      }
    }
  }

  &__resources {
    overflow-y: auto;
  }

  &__content {
    padding: @padding-2;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      margin-bottom: @margin-2;
      align-items: stretch;
    }

    &__main {
      flex: 1;
      min-height: 0;
    }
  }
}

.calendar-spin {
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }

  &.ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }
}

.calendar-override {
  .crm-calendar {
    &-day {
      &-header {
        flex-direction: column;
      }

      &-name {
        position: sticky;
        right: 80px;
        left: 80px;
        padding: 0 @padding-2;
        font-weight: bold;
        line-height: 1.25;
      }

      &-holiday {
        position: sticky;
        right: 80px;
        left: 80px;
        padding: 0 @padding-2;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        line-height: 1.25;
      }

      &-label {
        line-height: 1.25;
      }

      &-multiple {
        .crm-calendar-resource-label {
          padding: 0;
        }

        .crm-calendar-resources:empty:before {
          height: @crm-calendar-header-height - @crm-calendar-day-height;
          top: @crm-calendar-day-height;
        }
      }
    }

    &-resources:empty:before {
      content: '';
      position: sticky;
      top: 0;
      z-index: 1;
      height: @crm-calendar-header-height;
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #eeeeee;
    }
  }

  &--agenda:not(.calendar-override--date) {
    .crm-calendar-day-header {
      height: @crm-calendar-header-height;
    }

    .crm-calendar-resource-header {
      display: none;
    }

    .crm-calendar-marker {
      transform: none;
    }
  }
}

.calendar-default-event {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: @border-radius-base;
  cursor: pointer;
  overflow: hidden;

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    padding: 0 6px;
    overflow: hidden;
  }

  &__description {
    font-size: 12px;
    padding: 1px 6px;
  }

  &__popover {
    height: 100%;
  }
}

.calendar-popover {
  &--overlay {
    max-width: 300px;

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &__title {
    padding: 4px 8px;
    font-weight: bold;
    border-bottom: 1px solid @border-color-base;
  }

  &__content {
    padding: 4px 8px;

    &__title {
      font-weight: bold;
    }

    &__description {
      font-size: 12px;
    }
  }
}
