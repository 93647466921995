.record-view {
  background-color: #ffffff;
  padding: 24px;

  &__header {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid @c-gray-1;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__section {
    margin-bottom: 24px;
  }

  &__body {
    p {
      margin-bottom: 0;
    }
  }
}
