.attendance {
  background-color: @c-gray-4;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__date-time {
    width: 100%;
    text-align: center;
  }

  &__time {
    font-size: 48px;
    line-height: 1.5;
    font-weight: 600;
    color: @c-text-2;
  }

  &__date {
    font-size: 24px;
    line-height: 1.5;
    color: @c-text-2;
    margin-bottom: 20px;
  }


  &__waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__message {
      margin-top: 16px;
      text-align: center;
      font-size: 28px;
      line-height: 1.5;
      color: @c-text-2;
    }

    &__logo {
      max-width: 50%;

      img {
        width: 100%;
      }
    }
  }


  @media screen and (min-width: 1200px) {
    &__time {
      font-size: 72px;
    }

    &__date {
      font-size: 32px;
      margin-bottom: 32px;
    }

    &__waiting {
      &__message {
        margin-top: 24px;
        font-size: 42px;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    &__time {
      font-size: 96px;
    }

    &__date {
      font-size: 48px;
      margin-bottom: 30px;
    }

    &__waiting {
      &__message {
        margin-top: 32px;
        font-size: 56px;
      }
    }
  }
}
