/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '../node_modules/common-module/common-module.less';

// https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@btn-font-size-lg: @font-size-base;
@border-radius-base: 4px;

@form-vertical-label-padding: 0 0 2px;
@form-item-label-font-size: @font-size-sm;
@form-item-label-height: @height-lg;

@layout-body-background: @bg-color;

@typography-title-margin-bottom: 0;


@import "./assets/styles/index";

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: @bg-color;
}
